@import '../../../../styles/base/base-colors.scss';
@import '../../../../styles/mixins/mixins-typography.scss';

//background colors
$bottomBorder: 0;

//icons
$iconColor:$primaryThemeColor;


//offsets
$topOffset:0;
$rightOffset:20px;
$leftOffset:210px;


.contactTileInHeader{
  display: grid;
  height: 100%;
  grid-template-columns: repeat(3, max-content);
  align-items: center;
  align-content: center;
  grid-column-gap: 10px;
  grid-template-rows:1fr;
  position: absolute;
  top:$topOffset;
  right:$rightOffset;
  //left:$leftOffset;

}

@media screen and( max-width:1310px) {
  .contactTileInHeader{
    left:$leftOffset;
    right:auto;
  }
}
@media screen and( max-width:700px) {
  .contactTileInHeader{
    display: none;
  }
}
