.loaderContainer{
  width: 100%;
  padding: 150px 0 150px;
  display: flex;
  justify-content: center;
  .large-select-0{fill:#D08C00;}
  .large-select-1{fill:#188CFF;}
}


