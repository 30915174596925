@import '../../../../styles/base/base-colors.scss';
@import '../../../../styles/mixins/mixins-typography.scss';
//icons
$iconColor: #555555;

$linkColor:darken($iconColor,20%);

.contactTile{
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-column-gap: 10px;
  align-items: center;
  align-content: center;
  @include links(#d0b681,$linkColor,$linkColor,$linkColor);

  i{
    color: $iconColor;
  }
}
