@import '../../../../styles/base/base-colors.scss';
@import '../../../../styles/base/base-typography.scss';


//tile blocks
$linkTileBorderRadius:0;

//mobile menu
$mobileLinkWidth: 210px;

.linksTilesContainer{
  font: $fullHeaderFont;
  >div{
    display: flex;
  }
  .horizontalContainer{
    flex-direction: row;
    height: 100%;
    display: flex;
  }
  .verticalContainer{
    flex-direction: column;
    height: 100%;
    display: flex;
    .linkTile{
      > div{
        width: $mobileLinkWidth;

      }
    }
  }
  .linkTile{
    display: flex;

      >div{
        cursor: pointer;
        &.active{
          //background-color: $linkTileActiveBackgroundColor !important;
          //box-shadow:$linkTileActiveUnderline;
          border-bottom:$linkTileActiveBorderUnderline;
          //background-color: $headerActiveBackgroundColor;
          a{
            color: $headerLinkTileActiveColor !important;
          }

        }
        &:hover{
          box-shadow: $linkTileHoverUnderline;
        }
        padding: 14px 8px;
        margin: 1px 4px;
        align-self: center;
        border-radius: $linkTileBorderRadius;
        background-color: $linkTileBackgroundColor;

    a{
        color: $headerLinkTileColor;
        text-decoration: none;
        text-transform: capitalize;
        height: 100%;
        display: flex;
        align-items: center;

        &:hover{
          color: $headerLinkTileHoverColor !important;
        }
      }
    }


  }
}




