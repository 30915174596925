@import '../base/base-typography.scss';
@import '../base/base-colors.scss';

@mixin res($size) {
    @if $size == phone {
        @media (max-width: $mobilePageWidth) { @content; }
    }
    @else if $size == tablet {
        @media (max-width: $tabletPageWidth) { @content; }
    }
    @else if $size == smallLaptop {
        @media (max-width: $laptopSmallPageWidth) { @content; }
    }
    @else if $size == desktop {
        @media (max-width: $websitePageWidth) { @content; }
    }
}

@mixin links (
    $linkColor:$primaryLinkColor,
    $visitedLinkColor:$primaryVisitedLinkColor,
    $hoverLinkColor:$primaryHoverLinkColor,
    $activeLinkColor:$primaryActiveLinkColor
) {
    a {
        color: $linkColor !important;
        transition: color 350ms;
        text-decoration: none;
        &:visited {
            color: $visitedLinkColor;
        }
        &:hover {
            color: $hoverLinkColor;
            text-decoration: underline;
        }
        &:active {
            color: $activeLinkColor;
        }
    }
}


@mixin largeTextTitle($direction:center, $headingTextColor:$headingColor, $margin: 40px 0) {
    font-family: $quaternaryFont;
    font-size: 36px;
    color: $headingTextColor;
    text-align: $direction;
    justify-content: $direction;
    border-top: 2px solid $secondaryThemeColor;
    padding-top:40px;
    margin: $margin;
    @include res(smallLaptop) {
        line-height: 50px;
    }
    @include res(phone) {
        padding: 20px 0 10px;
        line-height: 50px;
        display: flex;
    }
}
@mixin semiLargeTextTitle($direction:center, $headingTextColor:$headingColor) {
    font-family: $primaryFont;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    color: $headingTextColor;
}

@mixin mediumTextTitle($direction:left) {
    font-family: $primaryFont;
    font-size: 22px;
    color: $headingColor;
    font-weight:400;
    text-align: $direction;
}

@mixin smallTextTitle($direction:left,$margin: 40px 0) {
    @include links();
    font-family: $primaryFont;
    font-size: 20px;
    color: $headingColor;
    font-weight:700;
    text-align: $direction;
    margin: $margin;
}

@mixin tinyTextTitle($direction:center, $headingTextColor:$headingColor) {
    font-family: $primaryFont;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0;
    font-weight:400;
    color:$headingTextColor;
    text-align: $direction;
    a{
        text-decoration: none;
        color: $primaryLinkColor;
    }
}

@mixin subTitle($direction:left,$margin:'20px 0') {
    @include links();
    font-family: $primaryFont;
    font-size: 18px;
    font-weight:400;
    color: $headingColor;
    text-align: $direction;
    margin:$margin;
    //a {
    //    text-decoration: none;
    //}
}

@mixin primaryBodyFont($direction:left) {
    font-family: $primaryFont;
    color:$primaryTextColor;
    font-size: $bodyFontSize;
    text-align: $direction;
}

@mixin sectionTitle($direction:center) {
    font-family: $secondaryFont;
    font-size: 20px;
    color: $headingColor;
    text-align: $direction;
}
@mixin sectionText($direction:left) {
    font-family: $primaryFont;
    font-size: 15px;
    font-weight:400;
    color: $headingColor;
    margin: 5px 0;
    text-align: $direction;
}
@mixin menuCategory($direction:center) {
    font-family: $quaternaryFont;
    font-size: 56px;
    text-align: $direction;
    padding: 0;
    //background-color: #181818;

}@mixin menuTitle($direction:center) {
    font-family: $primaryFont;
    font-size: 16px;
    color: $headingColor;
    text-align: $direction;
    margin: 0 0 2px;
    background-color: rgba(0,0,0,.58);
    padding: 10px;
    height: 48px;
    display: grid;
    justify-content: center;
    align-content: center;
}
@mixin menuText($direction:left) {
    font-family: $primaryFont;
    font-size: 15px;
    font-weight:400;
    color: $headingColor;
    margin: 5px 0;
    text-align: $direction;
}

@mixin buttonText() {
    font-family: $primaryFont;
    font-size: 15px;
    font-weight:400;
}
@mixin linkText() {
    font-family: $primaryFont;
    color: $primaryLinkColor;
    $primaryLinkHoverColor: #4363bc;
}
@mixin articleText() {
    font-family: $articleFont;
    font-size: 17px;
    line-height: 1.8rem;
    text-transform: capitalize;
}
@mixin captionText() {
    font-family: $captionFont;
    font-size: 14px;
    line-height: 1rem;
    color:#455275;
}
@mixin sliderHeaderStyle() {
    font-family: $secondaryFont;
    margin: 0 auto;
    max-width: 840px;
    color: #FFFFFF;
    font-size: 64px;
    line-height: 1;
}
@mixin animatedSliderInner() {
    font-family: $tertiaryFont;
    color: #FFFFFF;
    font-size: 24px;
    line-height: 1.5;
    margin: 20px auto 30px;
    max-width: 640px;
}
