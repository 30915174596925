//THEMES
$primaryThemeColor: #000000;
$secondaryThemeColor: #535353;
$secondaryThemeColor_dark-mode: #ffffff;

//FONTS -----
$primaryTextColor: #fff;
$secondaryTextColor: #2b2b2b;
$tertiaryTextColor: #eab55a;
$primaryTextColor_dark-mode: #ececec;

$generalBackgroundColor: #191919;

$headingColor: #ffffff;
$headingColor_dark-mode: #ececec;

$subHeadingColor: #6d6d6d;

$primaryLinkColor: #ffffff;
$primaryLinkHoverColor: darken($primaryLinkColor, 30%);

$linkActiveColor: #bf0109;

$secondaryLinkColor: #ffffff;
$secondaryLinkColor_dark-mode: #5db3ff;
$secondaryLinkHoverColor: #0f6599;

//BUTTONS -----
$primaryButtonColor: #c0872e;
$secondaryButtonColor: $primaryButtonColor;
$tertiaryButtonColor: #000;

$secondaryButtonColor_dark-mode: #282828;
$tertiaryButtonColor_dark-mode: #3f4040;

$lineColor: #999999;
$lineColorUnder: #ededed;
$iconDisable: #d7d7d7;
$alternativeRow: #fafafa;
$iconActive: #c0872e;

//BODY COLOR -----
$primaryBodyColor: #cc0000;
$secondaryBodyColor: #000000;
$tertiaryBodyColor: #EFEFEF;
$quaternaryBodyColor:#EFEFEF;
$quinaryBodyColor:#EFEFEF;

//FOOTER-----

//font colors
$footerLinkColor: #909298;
$footerLinkHoverColor: darken($footerLinkColor, 10%);;
$bottomTextColor: #707a83;
$textColor: #FFFFFF;
//backgroundColors
$footerBackgroundColor: #212121;
$footerTileColour: #e6e4e4;
$backgroundShade:#f7f7f7;

//LINKS
$LinkColor: $primaryLinkColor;
$LinkColor_dark-mode:#8cb4ee;
$primaryLinkColor: $LinkColor;
$primaryVisitedLinkColor: darken($primaryLinkColor, 10%);
$primaryHoverLinkColor: lighten($primaryLinkColor, 10%);
$primaryActiveLinkColor: darken($primaryLinkColor, 20%);


//HEADER-----

//background colors
$headerBackgroundColor: #000;
$headerActiveBackgroundColor: #f9f9f9;
$linkBlockBackgroundColor: #fdfeff;
$headerTopBarBackgroundColor:#fdfeff;

//HEADER LINK TILES
$linkTileBackgroundColor: transparent;
$linkTileActiveBackgroundColor: #fdfeff;

//HEADER FONT COLORS
$headerLinkTileColor: #fff;
$headerLinkTileColor_dark-mode: #7c92ad;
$headerLinkTileActiveColor: #fff;
$headerLinkTileActiveColor_dark-mode: $secondaryThemeColor;
$headerLinkTileHoverColor:darken($headerLinkTileColor, 10%);

//shadow underlines
$linkTileActiveUnderline: 0;
$linkTileHoverUnderline: 0;

//border underlines
$linkTileActiveBorderUnderline: 1px solid #c48d33;
$linkTileHoverBorderUnderline: 0;
