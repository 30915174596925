@import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Kaushan+Script&family=Cardo:ital@0;1&family=Lato:wght@300;400&display=swap');
//@import './base-layout.scss';

//FONT FACES
$primaryFont: 'Lato';
$secondaryFont: 'Yeseva One';
$tertiaryFont: 'Cardo';
$quaternaryFont: 'Kaushan Script';
$quinaryFont: 'serif';

$articleFont: $primaryFont;
$captionFont: 'serif';


$secondaryFullFont: 300 14px Lato, serif;

//FONT SIZES
$bodyFontSize: 14px;
$primaryBodyFontSize:14px;
$inputFontSize:16px;

//HEADER
$fullHeaderFont: 400 18px $primaryFont, serif;

//FOOTER
$fullFooterFont: 300 16px $secondaryFont, serif;

